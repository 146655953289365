.carousel-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.carousel-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding-bottom: 5px;
}

.carousel-items {
  max-width: 70vw; 
  margin: 0 auto; 
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
}

.carousel-item {
  display: inline-block; 
  padding: 10px;
  cursor: pointer;
}

.carousel-image {
  height: 75px;
  object-fit: cover;
}

.carousel-legend {
  margin-top: 10px;
  text-align: center;
}
