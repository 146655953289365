#panoramaVideoContainer {
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 100%;
  z-index: 50;
}

#360video {
  display: block;
}

#pauseVideoButton {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
}

#progressBarContainer {
  width: 100%;
  height: 5px;
  background-color: gray;
  position: absolute;
  bottom: 0;
}

#progressBar {
  height: 100%;
  background-color: blue;
}




