.hotspot-active{
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 100;
  position: absolute;
  display: block;
  background-color: orange;
  height: 50px;
  width: 50px; */
  /* height: 100%; */
  /* width: 100%; */
}

.hotspot-inactive{
  display: none;
}

.infospot-inactive{
  display: none;
}
