.div_heading {
    padding: 50px 0;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .statisticsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .source {
    display: block; 
    font-size: 0.75rem;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .source:hover {
    text-decoration: underline;
  }
  
  .statisticsCard {
    width: 200px;
    padding: 10px;
    text-align: center;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .percentage {
    font-size: 54px;
    color: #333;
    font-weight: bold;
    margin-bottom: 0;
  }
  
  h3 {
    font-size: 18px;
    color: #666;
    margin: 5px 0;
  }
  
  .statisticsSectionContainer {
    border-top: 5px solid #2196f3;
    text-align: center;
  }
  
  .statisticsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }  