@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400);
.hotspot-active{
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 100;
  position: absolute;
  display: block;
  background-color: orange;
  height: 50px;
  width: 50px; */
  /* height: 100%; */
  /* width: 100%; */
}

.hotspot-inactive{
  display: none;
}

.infospot-inactive{
  display: none;
}

.carousel-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.carousel-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding-bottom: 5px;
}

.carousel-items {
  max-width: 70vw; 
  margin: 0 auto; 
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
}

.carousel-item {
  display: inline-block; 
  padding: 10px;
  cursor: pointer;
}

.carousel-image {
  height: 75px;
  object-fit: cover;
}

.carousel-legend {
  margin-top: 10px;
  text-align: center;
}

.pano-canvas canvas {
}

.hotspot-wrapper {
  width: 80px;
  height: 80px;
}

.spot.hotspot {
  position: relative;
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid white;
  overflow: hidden;
}

.spot.hotspot.active .pulse-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid white; /* Use a visible border color */
  opacity: 1;
  -webkit-animation: pulse 1.5s ease-in-out infinite;
          animation: pulse 1.5s ease-in-out infinite; /* Adjust the duration as needed */
  -webkit-transform-origin: center;
          transform-origin: center; /* Pulsate from the center */
}

.spot.hotspot p {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  margin: 0;
}

.infospot {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.spot.infospot.active .pulse-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid white;
  opacity: 1;
  -webkit-animation: pulse 1.5s ease-in-out infinite;
          animation: pulse 1.5s ease-in-out infinite; /* Adjust the duration as needed */
  -webkit-transform-origin: center;
          transform-origin: center;
}

.info-input {
    margin-bottom: 10px;
}

.infospot-popconfirm-wrapper button{
  display: none;
}

.spot {
    cursor: pointer;
}

.spot p {
  text-align: center;
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.ant-popover{
  cursor: pointer;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); /* Slightly increase size */
    opacity: 0.5; /* Make it fade slightly */
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}


@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); /* Slightly increase size */
    opacity: 0.5; /* Make it fade slightly */
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
#panoramaVideoContainer {
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 100%;
  z-index: 50;
}

#360video {
  display: block;
}

#pauseVideoButton {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
}

#progressBarContainer {
  width: 100%;
  height: 5px;
  background-color: gray;
  position: absolute;
  bottom: 0;
}

#progressBar {
  height: 100%;
  background-color: blue;
}





#hero-text {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#hero-text h1 {
  font-size: 60px;
  color: white;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#hero-text h3 {
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.button-container {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
}

.panorama {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 767px) {

  .button-container {
    flex-direction: column;
  }
}


#features_div {
    padding: 0 5%;
    margin: 0;
}

.div_heading {
    margin: 20px 0;
    padding: 0;
    text-align: center;
    font-family: open sans;
    font-weight: 300;
    color: #525252;
    font-size: 42px;
    letter-spacing: 1px;
}

#features_list_div {
    justify-content: center;
    display: flex;
    flex-wrap:wrap;
    padding: 0 5%;
    align-items: stretch;
}

.features_item_div {
    padding: 0 30px;
    margin: 30px 0;
    display: flex;
    align-items: center;
    width: 40%;
}

.features_image_div {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #2196f3;
    border-radius: 100%;
}

.svg {
    -webkit-filter: invert(52%) sepia(16%) saturate(7141%) hue-rotate(184deg) brightness(96%) contrast(99%);
            filter: invert(52%) sepia(16%) saturate(7141%) hue-rotate(184deg) brightness(96%) contrast(99%);
}

.features_text_div {
    align-self: flex-start;
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
}

.features_text_div h1 {
    padding: 0;
    margin: 0 0 10px 0;
    font-family: open sans;
    font-weight: 300;
    color: #525252;
    font-size: 28px;
    text-align: center;
}

.div_subheading {
    color: #525252;
    font-family: open sans;
    font-size: 14px;
    text-align: center;
    padding: 0 0 15px 0;
    text-decoration: none;
}

@media only screen and (max-width: 991px) {

    #features_list_div {
        flex-direction: column;
    }

    .features_item_div {
        width: auto;
        flex-direction: column;
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .features_text_div {
        padding: 20px 0 0 0;
    }

    .features_text_div p {
        padding: 0 25%;
    }


}

@media only screen and (max-width: 767px) {

    #features_div {
        padding: 50px 5%;
    }

    .features_text_div p {
        padding: 0;
    }

    .div_heading {
        font-size: 28px;
        padding: 0;
    }

    .div_subheading {
        padding: 0 0 20px 0;
        margin: 0;
    }
}

.MuiContainer-root{
  padding: 0;
  margin: 0;
}

a {
    text-decoration: none;
    transition: color 0.25s ease;
}

body {
    margin: 0;
}

.button {
    margin: 0 30px;
    font-family: montserrat;
    color: #e2e2e2;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 0.25s ease;
}

.button:hover {
    opacity: 0.5;
    color: #e2e2e2;
}

.left {
    text-align: left !important;
}

.light {
    color: #e2e2e2 !important;
}

.home-wrapper{
    margin-top: -40px;
    width: 100%;
    height: 100vh;
}

@media only screen and (max-width: 767px) {
    .button {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

@media (min-width: 600px){
  .MuiContainer-root{
  padding: 0;
  margin: 0;
  }
}

.faq_div {
  padding: 0 5%;
}

.faq_item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;
}

.faq_question {
  display: flex;
  justify-content: space-between;
}

.faq_question_text {
  font-weight: bold;
  flex: 1 1;
}

.faq_icon {
  margin-left: 10px;
}

.faq_answer {
  transition: all 0.3s ease;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  display: none;
}

.show {
  display: block;
}

.div_heading {
    padding: 50px 0;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .statisticsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .source {
    display: block; 
    font-size: 0.75rem;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .source:hover {
    text-decoration: underline;
  }
  
  .statisticsCard {
    width: 200px;
    padding: 10px;
    text-align: center;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .percentage {
    font-size: 54px;
    color: #333;
    font-weight: bold;
    margin-bottom: 0;
  }
  
  h3 {
    font-size: 18px;
    color: #666;
    margin: 5px 0;
  }
  
  .statisticsSectionContainer {
    border-top: 5px solid #2196f3;
    text-align: center;
  }
  
  .statisticsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
  }  
body {
    background-color: #f7f8fc;
}

* {
    /* font-family: 'Noto Sans JP' !important; */
    font-family: 'Heebo', sans-serif !important;
}

.containerPadding {
    padding-top: 90px;
  }
