.faq_div {
  padding: 0 5%;
}

.faq_item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;
}

.faq_question {
  display: flex;
  justify-content: space-between;
}

.faq_question_text {
  font-weight: bold;
  flex: 1;
}

.faq_icon {
  margin-left: 10px;
}

.faq_answer {
  transition: all 0.3s ease;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  display: none;
}

.show {
  display: block;
}
