@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400);

.MuiContainer-root{
  padding: 0;
  margin: 0;
}

a {
    text-decoration: none;
    transition: color 0.25s ease;
}

body {
    margin: 0;
}

.button {
    margin: 0 30px;
    font-family: montserrat;
    color: #e2e2e2;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 0.25s ease;
}

.button:hover {
    opacity: 0.5;
    color: #e2e2e2;
}

.left {
    text-align: left !important;
}

.light {
    color: #e2e2e2 !important;
}

.home-wrapper{
    margin-top: -40px;
    width: 100%;
    height: 100vh;
}

@media only screen and (max-width: 767px) {
    .button {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

@media (min-width: 600px){
  .MuiContainer-root{
  padding: 0;
  margin: 0;
  }
}
