.pano-canvas canvas {
}

.hotspot-wrapper {
  width: 80px;
  height: 80px;
}

.spot.hotspot {
  position: relative;
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid white;
  overflow: hidden;
}

.spot.hotspot.active .pulse-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid white; /* Use a visible border color */
  opacity: 1;
  animation: pulse 1.5s ease-in-out infinite; /* Adjust the duration as needed */
  transform-origin: center; /* Pulsate from the center */
}

.spot.hotspot p {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  margin: 0;
}

.infospot {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.spot.infospot.active .pulse-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid white;
  opacity: 1;
  animation: pulse 1.5s ease-in-out infinite; /* Adjust the duration as needed */
  transform-origin: center;
}

.info-input {
    margin-bottom: 10px;
}

.infospot-popconfirm-wrapper button{
  display: none;
}

.spot {
    cursor: pointer;
}

.spot p {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.ant-popover{
  cursor: pointer;
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3); /* Slightly increase size */
    opacity: 0.5; /* Make it fade slightly */
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}