#hero-text {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#hero-text h1 {
  font-size: 60px;
  color: white;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#hero-text h3 {
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.panorama {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 767px) {

  .button-container {
    flex-direction: column;
  }
}
